import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/Layout'
import CircularProgress from '@material-ui/core/CircularProgress';


export default class Staff extends React.Component {
    componentDidMount(){
       navigate('/kontakt/') 
    }
  render() {
   
    return (
        <Layout>
            <div>
                <CircularProgress/>
            </div>
        </Layout>
    )
  }
}
